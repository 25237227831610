@use '@angular/material' as mat;

@include mat.core();

// Set the default typography
@include mat.all-component-typographies(
    mat.define-typography-config($font-family: "Poppins, sans-serif")
);

$my-primary: mat.define-palette(mat.$green-palette, 600);
$my-accent: mat.define-palette(mat.$amber-palette, 600);


// Define the default theme
$default-theme: mat.define-dark-theme(
    (
      color: (
        primary: $my-primary,
        accent: $my-accent,
      ),
      typography:
      mat.define-typography-config(
        $font-family: "Poppins, sans-serif",
      ),
      density: 0,
    )
);

// Emit theme-dependent styles for common features used across multiple components.
@include mat.core-theme($default-theme);
// Emit styles for MatButton based on `$my-theme`. Because the configuration
// passed to `define-light-theme` omits typography, `button-theme` will not
// emit any typography styles.
@include mat.button-theme($default-theme);


@include mat.all-component-themes($default-theme);

:root {
  --color-primary: $my-primary;
  --color-accent: $my-accent;
}
