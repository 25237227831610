/* You can add global styles to this file, and also import other style files */

@import "@fortawesome/fontawesome-free/css/all.css";
@import "mat-theme";
@import "styles/colors.scss";
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  margin: 0;
  font-family: Poppins, sans-serif;
  background-color: #393939;
}

app-root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

button.mat-mdc-raised-button {
  padding: 1em 1.5em;
  font-weight: 500;
  height: auto;
  letter-spacing: normal;
  font-size: 1.2em;

  .mdc-button__label {
    display: flex;
    align-items: center;
  }

  .icon {
    margin-right: .5em;
    font-size: 1.2em;
  }
}

.mat-body, .mat-body-2, .mat-typography .mat-body, .mat-typography .mat-body-2, .mat-typography {
  letter-spacing: .015em;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.page .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2em 0;
  margin-bottom: 2em;

  .title {
    color: $white;
    font-size: 2rem;
    font-weight: 600;
  }
}

.page {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.page .container {
  max-width: max-content;
  min-width: 1300px;
  padding: 1em;
  margin: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.page .content {
  flex: 1;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  mat-spinner {
    transform: translateY(-100%);
  }
}

.mat-mdc-table.cdk-table {
  background-color: #555555;
}

.mat-mdc-table.cdk-table thead {
  background-color: #333333;
}

.mat-mdc-table.cdk-table tbody {
  background-color: #555555;
}

th {
  font-weight: 600;
  font-size: 1.2em;
}

th.mat-mdc-header-cell, td.mat-mdc-cell {
  padding: 1rem 2rem;

  &.text-center {
    text-align: center;
  }
}

th.text-center .mat-sort-header-container {
  justify-content: center;
}

td.no-data-cell, .no-results {
  text-align: center;
  padding: 2rem 0;
  color: #a5a5a5;
  font-size: 1.8em;
  font-weight: 600;
}

.icon-button:hover {
  color: #ffc0cb;
}

mat-dialog-actions.mat-mdc-dialog-actions {
  justify-content: space-between;
}

fa-icon.remove-icon {
  color: #ad5a5a;
}

table.mat-mdc-table a {
  font-weight: 600;
  color: #bdffc0;
}

[hidden] {
  display: none !important;
}

.mat-mdc-dialog-surface {
  min-width: max-content;
}

.mat-column-notes {
  max-width: 500px;
}

.filters-container {
  display: flex;
  align-items: flex-start;
  column-gap: 1em;
}

.mat-select-search {
  margin-top: -8px;
}

/* Custom style for button-like radio buttons */
.radio-button .mdc-label{
  display: inline-block;
  cursor: pointer;
  margin-right: 2px; /* Adjust the spacing */
  padding: 1.25em 2em; /* Padding to give it button-like shape */
  border-radius: 4px;
  background-color: rgb(74 74 74); /* Light background */
  color: rgba(255, 255, 255, 0.87); /* Text color */
}

.radio-button .mdc-radio {
  display: none;
}

.mat-mdc-radio-button.radio-button:hover {
  filter: brightness(1.1) /* Darken background on hover */
}

.mat-mdc-radio-button.radio-button.mat-mdc-radio-checked .mdc-label{
  background-color: #43a047; /* Selected background color */
  color: #fff; /* White text when selected */
}

.mat-mdc-radio-button.radio-button.mat-mdc-radio-checked:hover .mdc-label{
  background-color: #49a14c; /* Darken on hover when selected */
}


